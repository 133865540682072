import {Component} from 'react';

import Box from '@material-ui/core/Box';
import ErrorIcon from '@material-ui/icons/SentimentVeryDissatisfiedOutlined';

import ErrorDetails from './common/ErrorDetails';

export default class AreaBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    };
  }

  static getDerivedStateFromError(error) {
    return {
      error
    };
  }

  render() {
    const {children} = this.props;
    const {error} = this.state;

    if (error) {
      return (
        <Box paddingX={2} paddingY={8}>
          <ErrorDetails error={error} icon={ErrorIcon} summary='The module failed to load.' />
        </Box>
      );
    }

    return children;
  }
}
