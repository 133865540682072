import {createBrowserHistory} from 'history';
import {AuthProvider, useAuth} from 'oidc-react';
import {StrictMode} from 'react';
import {render} from 'react-dom';
import {Router} from 'react-router-dom';
import {Provider as HttpProvider} from 'use-http';

import CssBaseline from '@material-ui/core/CssBaseline';

import App from './App';

const baseUrl = (url =>
  (url.origin + url.pathname).replace(/\/*$/, '').toLowerCase()
)(new URL(document.baseURI));

const apiUrl = process.env.REACT_APP_API_URL;
const oidcClientId = process.env.REACT_APP_OIDC_CLIENT_ID;
const oidcUrl = process.env.REACT_APP_OIDC_URL;
const title = document.title;

const history = createBrowserHistory({
  basename: new URL(baseUrl).pathname
});

function Auth({children}) {
  return (
    <AuthProvider
      authority={oidcUrl}
      autoSignIn={true}
      automaticSilentRenew={true}
      children={children}
      clientId={oidcClientId}
      onSignIn={user => {
        history.replace('/');
      }}
      redirectUri={baseUrl}
      responseType='id_token token'
      scope='email openid profile' />
  )
}

function Http({children}) {
  const auth = useAuth();

  return (
    <HttpProvider
      children={children}
      options={{
        cachePolicy: 'no-cache',
        interceptors: {
          request: async ({ options }) => {
            options.headers = options.headers || {};
            options.headers['Authorization'] = `Bearer ${auth.userData.id_token}`;

            return options;
          }
        }
      }}
      url={apiUrl} />
  );
}

render((
  <StrictMode>
    <CssBaseline />
    <Router history={history}>
      <Auth>
        <Http>
          <App title={title} />
        </Http>
      </Auth>
    </Router>
  </StrictMode>
), document.getElementById('app'));
