import {makeStyles} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';

const useStyles = makeStyles(theme => ({
  user: {
    ...theme.mixins.toolbar,
    ...theme.typography.h6,
    color: theme.palette.text.primary,
    padding: theme.spacing(2)
  }
}));

export default function Navbar ({children, className, fixed, onClose, open, ...props}) {
  const classes = useStyles();

  return (
    <Drawer {...props} classes={{paper: className}} onClose={onClose} open={open} variant={fixed ? 'permanent' : 'temporary'}>
      <div className={classes.user} />
      <Divider />
      <List>
        {children}
      </List>
    </Drawer>
  );
}
