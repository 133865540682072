import {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useFetch} from 'use-http';

import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/LinkOff';
import Grid from '@material-ui/core/Grid';

import {sortStudents} from '../common/sorting';

import ErrorDetails from '../common/ErrorDetails';

const useStyles = makeStyles(theme => ({
  action: {
    margin: theme.spacing(2)
  },
  actions: {
    display: 'flex',
    justifyContent: 'center'
  },
  confirmation: {
    fontSize: '3em',
    textAlign: 'center'
  },
  controls: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: theme.spacing(3)
  },
  tile: {
    backgroundColor: theme.palette.grey.A100,
    borderRadius: '0.25em',
    cursor: 'pointer',
    fontSize: '2em',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'center',
    textDecoration: 'none'
  }
}));

export default function KioskView() {
  const classes = useStyles();
  const {groups: {year, month, day}} = new Date().toISOString().match(/^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})/);

  const [choices, setChoices] = useState();
  const [selected, setSelected] = useState();

  const {error, get: reload} = useFetch(`/attendance-state/${year}/${month}/${day}`, {
    onNewData: (_, data) => {
      setChoices(sortStudents(data.filter(data => data.attendanceId !== null && data.signingId === null)));
    },
    responseType: 'json'
  }, []);

  const {loading: isSigning, post: signOut} = useFetch(`/attendance`, {
    interceptors: {
      response: async ({response}) => {
        // trigger data refresh:
        await reload();

        return response;
      }
    }
  });

  return (
    <Box padding={6}>
      <div className={classes.controls}>
        <Button color='inherit' component={RouterLink} to='/'>
          Exit tablet view
        </Button>
      </div>

      {error ? (
        <ErrorDetails error={error} icon={ErrorIcon} summary='Roster request failed.' />
      ) : choices && (selected ? (
        <>
          <div className={classes.confirmation}>
            {selected.firstName} {selected.lastName}
          </div>

          <div className={classes.actions}>
            <Button className={classes.action} color='primary' disabled={isSigning} onClick={() => signOut(`${selected.attendanceId}/signing`).then(() => setSelected()).catch(() => {})} size='large' variant='contained'>
              Sign out
            </Button>

            <Button className={classes.action} color='secondary' disabled={isSigning} onClick={() => setSelected()} size='large' variant='contained'>
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <Grid container>
          {choices.map(student =>
            <Grid key={student.id} item xs={12} sm={6} md={4}>
              <div className={classes.tile} onClick={() => setSelected(student)}>
                {student.firstName} {student.lastName}
              </div>
            </Grid>
          )}
        </Grid>
      ))}
    </Box>
  );
}
