const grades = {
  K: -1
};

export function groupStudentsByGrade(list) {
  const map = {};

  for (const student of list) {
    if (!map[student.grade]) {
      map[student.grade] = [];
    }

    map[student.grade].push(student);
  }

  return sortGrades(Object.keys(map)).map(grade => ({
    grade,
    students: sortStudents(map[grade])
  }));
}

export function sortGrades(list) {
  return list.sort((a, b) => (grades[a] || +a) - (grades[b] || +b));
}

export function sortStudents(list) {
  return list.sort((a, b) =>
    a.lastName.localeCompare(b.lastName) ||
    a.firstName.localeCompare(b.firstName));
}
