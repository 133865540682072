import Box from '@material-ui/core/Box';
import ErrorIcon from '@material-ui/icons/LinkOff';

import ErrorDetails from './common/ErrorDetails';

export default function NotFoundPage ({error}) {
  return (
    <Box paddingX={2} paddingY={8}>
      <ErrorDetails error={error} icon={ErrorIcon} summary='Page not found.' />
    </Box>
  );
}
