import {Route, Switch} from 'react-router-dom';

import AttendanceEditPage from './AttendanceEditPage';
import NotFoundPage from '../NotFoundPage';

export default function AttendanceRouter() {
  return (
    <Switch>
      <Route path='/:date(\d{4}-\d{2}-\d{2})?' exact component={AttendanceEditPage} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}
