import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.grey.A100,
    fontSize: '14rem',
    padding: theme.spacing(2)
  },
  placeholder: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  summary: {
    fontWeight: 'bold'
  }
}));

export default function ErrorDetails ({className = '', error, icon: Icon = 'div', summary, ...props}) {
  const classes = useStyles();

  return (
    <Container {...props} className={`${className} ${classes.placeholder}`.trim()} maxWidth='sm'>
      <Icon className={classes.icon} />

      {summary &&
        <Typography className={classes.summary}>
          {summary}
        </Typography>
      }

      {error &&
        <>
          <code>{error.message}</code>
        </>
      }
    </Container>
  );
}
