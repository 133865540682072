import {Link as RouterLink} from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export default function NavbarLink ({icon: Icon = 'div', title, ...props}) {
  return (
    <ListItem {...props} button component={RouterLink}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
}
