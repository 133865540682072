import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useFetch} from 'use-http';

import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import ErrorIcon from '@material-ui/icons/LinkOff';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

import {groupStudentsByGrade, sortStudents} from '../common/sorting';

import ErrorDetails from '../common/ErrorDetails';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(3)
  },
  groupHeader: {
    backgroundColor: theme.palette.background.paper
  },
  groupList: {
    overflow: 'auto',
    padding: 0,
    position: 'relative'
  },
  heading: {
    ...theme.typography.h6,
    padding: theme.spacing(2)
  },
  list: {
    padding: 0
  }
}));

export default function AttendanceEditPage() {
  const classes = useStyles();

  const {date = new Date().toISOString()} = useParams();
  const {groups: {year, month, day}} = date.match(/^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})/);

  const [all, setAll] = useState();
  const [present, setPresent] = useState();

  const {error, get: reload} = useFetch(`/attendance-state/${year}/${month}/${day}`, {
    onNewData: (_, data) => {
      setAll(groupStudentsByGrade(data));
      setPresent(sortStudents(data.filter(data => data.attendanceId !== null)));
    },
    responseType: 'json'
  }, []);

  if (error) {
    return (
      <ErrorDetails error={error} icon={ErrorIcon} summary='Roster request failed.' />
    );
  }

  function StudentRow({student}) {
    const {loading: isMarking, del: markAbsent, post: markPresent} = useFetch(`/attendance-state/${year}/${month}/${day}/attendance`, {
      interceptors: {
        response: async ({response}) => {
          // trigger data refresh:
          await reload();
  
          return response;
        }
      }
    });

    const {loading: isSigning, post: signOut} = useFetch(`/attendance`, {
      interceptors: {
        response: async ({response}) => {
          // trigger data refresh:
          await reload();
  
          return response;
        }
      }
    });
  
    return (
      <ListItem divider={true} key={student.id}>
        <ListItemText>{student.firstName} {student.lastName}</ListItemText>
        {student.attendanceId ? (
          <>
            <Button disabled={isMarking || isSigning || !!student.signingId} onClick={() => markAbsent(student.id).catch(() => {})}>
              {student.signingId ? 'Signed out' : 'Mark absent'}
            </Button>
  
            {!student.signingId &&
              <Button disabled={isMarking || isSigning} onClick={() => signOut(`${student.attendanceId}/signing`).catch(() => {})}>
                Sign out
              </Button>
            }
          </>
        ) : (
          <Button disabled={isMarking || isSigning} onClick={() => markPresent(student.id).catch(() => {})}>
            Mark present
          </Button>
        )}
      </ListItem>
    );
  }

  return (
    <Box padding={3}>
      {present &&
        <Card className={classes.card}>
          <Typography className={classes.heading}>
            Attendance list
          </Typography>
          <List className={classes.list}>
            {present.map(student =>
              <StudentRow key={student.id} student={student} />
            )}
          </List>
        </Card>
      }

      {all &&
        <Card className={classes.card}>
          <Typography className={classes.heading}>
            All students
          </Typography>
          <List className={classes.groupList}>
            {all.map(({grade, students}) =>
              <li key={grade}>
                <ul className={classes.list}>
                  <ListSubheader className={classes.groupHeader}>
                    Grade {grade}
                  </ListSubheader>
                  {students.map(student =>
                    <StudentRow key={student.id} student={student} />
                  )}
                </ul>
              </li>
            )}
          </List>
        </Card>
      }
    </Box>
  );
}

