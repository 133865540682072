import { useAuth } from 'oidc-react';
import {Suspense, useEffect, useState} from 'react';
import {Link as RouterLink, Route, Switch, useLocation} from 'react-router-dom';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import AttendanceIcon from '@material-ui/icons/ListAlt';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import AreaBoundary from './AreaBoundary';
import AreaPlaceholder from './AreaPlaceholder';
import AttendanceRouter from './attendance/AttendanceRouter';
import KioskView from './kiosk/KioskView';
import Navbar from './Navbar';
import NavbarLink from './NavbarLink';
import NotFoundPage from './NotFoundPage';

const navbarBreakpoint = 'md';
const navbarWidth = '18em';

const useStyles = makeStyles(theme => ({
  navbar: {
    width: navbarWidth
  },
  offset: {
    [theme.breakpoints.up(navbarBreakpoint)]: {
      marginLeft: navbarWidth,
      width: `calc(100% - ${navbarWidth})`
    }
  },
  title: {
    flex: 1
  },
  toggle: {
    [theme.breakpoints.up(navbarBreakpoint)]: {
      display: 'none'
    },
    marginRight: theme.spacing(2)
  }
}));

export default function App ({title}) {
  const auth = useAuth();
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();

  const isNavbarFixed = useMediaQuery(theme.breakpoints.up(navbarBreakpoint));
  const [isNavbarOpen, setNavbarOpen] = useState(false);

  useEffect(() => {
    setNavbarOpen(false);
  }, [location.pathname]);

  return auth && auth.userData ? (
    <Switch>
      <Route path='/kiosk' component={KioskView} />
      <Route>
        <div className={classes.offset}>
          <AppBar position='sticky'>
            <Toolbar>
              <IconButton className={classes.toggle} color='inherit' edge='start' onClick={() => setNavbarOpen(!isNavbarOpen)}>
                <MenuIcon />
                <Typography variant='srOnly'>
                  {isNavbarOpen ? 'Collapse navigation' : 'Expand navigation'}
                </Typography>
              </IconButton>
              <Link className={classes.title} color='inherit' component={RouterLink} to='/' underline='none' variant='h6'>
                {title}
              </Link>
              <Button color='inherit' component={RouterLink} to='/kiosk'>
                Enter tablet view
              </Button>
            </Toolbar>
          </AppBar>
          <AreaBoundary>
            <Suspense fallback={<AreaPlaceholder />}>
              <Switch>
                <Route path='/' component={AttendanceRouter} />
                <Route component={NotFoundPage} />
              </Switch>
            </Suspense>
          </AreaBoundary>
        </div>
        <Navbar className={classes.navbar} fixed={isNavbarFixed} onClose={() => setNavbarOpen(false)} open={isNavbarOpen}>
          <NavbarLink icon={AttendanceIcon} title='Attendance' to='/' />
        </Navbar>
      </Route>
    </Switch>
  ) : null;
}
